<template>
  <div class="login">
    <div class="container-xl pt-5 pb-5">
      <div class="card m-auto p-3">
        <div class="card-body">
          <h3>Помогите нам защитить ваш аккаунт</h3>
          <p>
            Мы разработали новую <public-link to="/page/privacy-policy">политику безопасности.</public-link> <br />
            Пожалуйста, смените пароль, чтобы он соответствовал требованиям надежности.
            <br/>
            <div class="mt-3">
              <div v-if="!changePasswordLinkSent">
                <a @click.stop.prevent="changePassword" href="#" class="btn btn-outline-success">Сменить пароль</a>
              </div>
              <div v-else class="text-success">
                На вашу почту была отправлена ссылка для изменения пароля.
              </div>
            </div>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {InputErrors, PublicLink} from "@/components/common";
import {Forbidden, Unauthorized} from "@/utils/api-client";
import {createAuthManager, createAuthTokenManager, createProfileManager} from "@/utils/di";

export default {
  name: "OutdatedPassword",
  components: {
    InputErrors,
    PublicLink
  },
  data() {
    return {
      processing: false,
      changePasswordLinkSent: false,
      errors: {}
    };
  },
  methods: {
    async changePassword() {
      this.processing = true;
      try {
        this.changePasswordLinkSent = false;
        const manager = await createProfileManager();
        await manager.changePasswordRequest();
        this.changePasswordLinkSent = true;
      } finally {
        this.processing = false;
      }
    },
  }
};
</script>

<style lang="scss">
.login {
  .card {
    max-width: 35rem;

    .input-group-append {
      .btn-light,
      .btn-light:hover,
      .btn-light:focus,
      .btn-light:active {
        background: #fff;
        border-color: #ced4da;
        border-left: none;
      }
    }

    .input-errors {
      .small {
        font-size: 1rem;
      }
    }
  }
}
</style>
